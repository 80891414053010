import { isDEVMODE, globalStorage } from '../_globals'
import { gsap } from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
gsap.registerPlugin(ScrollToPlugin)

export default class Logo24 {

  constructor(el) {

    this.DOM = { el: el }
    this.DOM.numbers = el.querySelectorAll('.l24__numbers span')

    this.init()
    this.addEvents()

  }

  init() {

    if (isDEVMODE) console.log('Init Logo24')

    const { numbers } = this.DOM
    this.height = numbers[0].offsetHeight
    gsap.set(numbers, { y: (i) => i * this.height });

    this.TL = gsap.timeline({
      defaults: {
        duration: 1,
        ease: 'none'
      }
    })

    this.TL.to(numbers, {
      y: () => `-=${this.height}`,
      modifiers: { y: gsap.utils.unitize(y => parseFloat(y) % (numbers.length * this.height)) },
      onComplete: () => {
        const { isLoading } = globalStorage
        if(isLoading) this.TL.restart(1)
        else {
          gsap.set(numbers, { y: (i) => i * this.height })
          gsap.to(numbers, {
            duration: 1,
            ease: 'power4.out',
            y: () => `-=${this.height}`,
            modifiers: { y: gsap.utils.unitize(y => parseFloat(y) % (numbers.length * this.height)) }
          })
        }
      }
    })
  }

  addEvents() {
    
    const { el } = this.DOM
    this.play = this.play.bind(this)
    this.onResize = this.onResize.bind(this)
    this.resizeTimeout = setTimeout(() => {}, 0)
    this.windowWidthResize = window.innerWidth
    window.addEventListener('resize', this.onResize)
    
  }

  play() {
    this.TL.restart(1)
    gsap.fromTo(this.TL, { timeScale : 0 }, { timeScale : 8, duration: 1, ease: 'power3.in' })
  }

  pause() {
    gsap.to(this.TL, { timeScale : 1, ease: 'power3.out' })
  }

  onResize(forced = false) {
    console.log('onResize')
    clearTimeout(this.resizeTimeout)
    this.resizeTimeout = setTimeout(() => {
      if (forced || this.windowWidthResize !== window.innerWidth) {
        this.windowWidthResize = window.innerWidth
        const { numbers } = this.DOM
        this.TL.invalidate()
        this.height = numbers[0].offsetHeight
        gsap.killTweensOf(numbers)
        gsap.set(numbers, { y: (i) => i * this.height })
        setTimeout(() => {
          gsap.to(numbers, {
            duration: 1,
            ease: 'power4.out',
            y: () => `-=${this.height}`,
            modifiers: { y: gsap.utils.unitize(y => parseFloat(y) % (numbers.length * this.height)) }
          })
        }, 25)
      }
    }, 120)
  }

  destroy() {

    if (isDEVMODE) console.log('Destroy Logo24')
    this.TL && this.TL.kill(true)

  }
}
