import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals'
import Anchors from '../modules/anchors'
import CharteEthiqueConvictions from '../animations/charte-ethique-convictions'

export default class PageCharteEthique extends Renderer {
  initialLoad() {
    const onDOMContentLoaded = () => {
      document.removeEventListener('DOMContentLoaded', onDOMContentLoaded)
      this.onEnter()
    }

    document.addEventListener('DOMContentLoaded', onDOMContentLoaded)
  }

  onEnter() {
    // DOM
    this.DOM = {
      navigation: viewStorage.current.querySelector('.CharteEthiqueNavigation'),
      convictions: viewStorage.current.querySelector('.CharteEthiqueConvictions')
    }

    // Modules
    if (this.DOM.navigation) this.anchorsModule = new Anchors(this.DOM.navigation)
    if (this.DOM.convictions) this.convictionsModule = new CharteEthiqueConvictions({ section: this.DOM.convictions })
  }

  onLeaveCompleted() {
    // Destroy
    if (this.anchorsModule) this.anchorsModule.destroy()
    if (this.convictionsModule) this.convictionsModule.destroy()
  }
}
