/* global gtag */

/* --- Load Plugins / Functions --- */
import { isDEVMODE, globalStorage, viewStorage, domStorage } from './_globals'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { GlobalIntro } from './animations/global-intro'

import viewScroll from './modules/view-scroll.js'
import viewPrllx from './modules/view-prllx.js'
import viewInView from './modules/view-inview.js'
import viewSplited from './modules/view-splited.js'
import viewModals from './modules/view-modal'

/* --- DOMContentLoaded Function --- */
export const onReady = () => {

  const { body, header } = domStorage

  viewStorage.viewScroll = new viewScroll()
  viewStorage.viewPrllx = new viewPrllx(body)
  viewStorage.viewInView = new viewInView(body)
  viewStorage.viewModals = new viewModals(body)
  viewStorage.viewSplited = new viewSplited(body)

  setTimeout(() => window.scrollTo(globalStorage.pageScrollTop, globalStorage.pageScrollTop), 100)
  ScrollTrigger.refresh()
  
  globalStorage.isLoading = false

  // Intro
  GlobalIntro()

  // Header Color
  const { headerWhite } = viewStorage.current.dataset
  if (headerWhite !== undefined) header.classList.add('--w')

}

export const onEnter = (to, trigger) => {

  console.log('onEnter')

  // LoadingClasses
  const { body, header } = domStorage
  body.classList.remove('--loading')
  
  globalStorage.isLoading = false
  globalStorage.Header.logo24.pause()

  // ScrollTop
  window.scrollTo(globalStorage.pageScrollTop, globalStorage.pageScrollTop)

  // Update Current View
  viewStorage.current = to.renderer.content

  // Add Page Effects
  const { hasSmoothScroll } = viewStorage
  body.style.overflow = ''

  if (hasSmoothScroll) viewStorage.viewScroll.scroll.start()
  viewStorage.viewPrllx = new viewPrllx()
  viewStorage.viewInView = new viewInView()
  viewStorage.viewSplited = new viewSplited()
  viewStorage.viewModals = new viewModals()

  const { headerWhite } = viewStorage.current.dataset
  console.log(headerWhite)
  headerWhite !== undefined ? header.classList.add('--w') : header.classList.remove('--w')

}

export const onEnterCompleted = (to, from, trigger) => {

  if (isDEVMODE) console.log('onEnterCompleted')

  const { targetLocation } = globalStorage.taxi

  // Anchor
  if (targetLocation.hasHash) {
    const anchorEl = document.querySelector(window.location.hash)
    if (anchorEl) gsap.to(window, {duration: 1.8, scrollTo: { y: anchorEl, autoKill: false }, overwrite: 'all', ease: 'expo.inOut' })
  }

  // Analytics
  if (globalStorage.tarteaucitron && globalStorage.tarteaucitron.job.includes('gtag') && typeof gtag !== 'undefined') {
    gtag('config', globalStorage.tarteaucitron.user.gtagUa, {
      'page_path': targetLocation.pathname,
      'page_title': to.page.title,
      'page_location': targetLocation.href
    })
  }

}

export const onLeave = (from, trigger) => {

  // Remove Previous Page Effects
  const { hasInView, hasSticky, hasPrllx, hasSplited, hasModals, hasCursor } = viewStorage

  hasInView && viewStorage.viewInView.destroy()
  hasSplited && viewStorage.viewSplited.destroy()
  hasSticky && viewStorage.viewSticky.destroy()
  hasPrllx && viewStorage.viewPrllx.destroy()
  hasModals && viewStorage.viewModals.destroy()
  hasCursor && globalStorage.Cursor.removeHovers()

  // LoadingClasses
  const { body } = domStorage
  body.classList.add('--loading')
  body.classList.add('--animating')
  
  globalStorage.isLoading = true
  globalStorage.Header.logo24.play()


  const { menu, menuFooter } = domStorage
  const { menuOpen } = globalStorage

  // Close Menu
  menuOpen && globalStorage.closeMobileMenu()

  // Remove active links
  const { targetLocation } = globalStorage.taxi
  const navLinks = menu.querySelectorAll('.Nav__link')
  const navFooterLinks = menuFooter.querySelectorAll('.Nav__link')

  const updateActives = (link) => {
    if (link.href === targetLocation.raw) link.classList.add('--active')
    else link.classList.remove('--active')
  }
  
  navLinks.forEach((link) => updateActives(link))
  navFooterLinks.forEach((link) => updateActives(link))

}
