import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

export default class CharteEthiqueConvictions {
  constructor({ section }) {
    if (!section) return

    // DOM
    this.DOM = { section }
    this.DOM.title = this.DOM.section.querySelector('.container > h2')
    this.DOM.items = this.DOM.section.querySelectorAll('.item')

    console.log(this.DOM)

    // MatchMedia variable
    this.mm = gsap.matchMedia()
    // Breakpoint
    this.breakpoint = 768
    // Responsive object
    this.responsiveObj = {
      isDesktop: `(min-width: ${this.breakpoint}px)`,
      isMobile: `(max-width: ${this.breakpoint - 1}px)`
    }

    this.setTimeline() 
  }

  setTimeline() {
    this.mm.add(this.responsiveObj, (context) => {
      const { isDesktop } = context.conditions

      if (isDesktop) {
        // gsap.set([ this.DOM.title, this.DOM.items ], { yPercent: 100 })

        const timeline = gsap.timeline({
          defaults: { 
            ease: 'power2.out', 
            duration: 1 
          },
          scrollTrigger: {
            trigger: this.DOM.section,
            start: 'center center',
            end: '+=280%',
            pin: true,
            scrub: true,
          }
        })

        if (this.DOM.title) {
          timeline.fromTo(this.DOM.title, {
            yPercent: 100, 
            opacity: 0 
          }, { 
            yPercent: 0,
            opacity: 1
          }, 0)
        }

        timeline.fromTo(this.DOM.items, {
          yPercent: 100,
          scale: 0.5,
          rotate: (i, el, els) => i % 2 === 0 ? -12 : 8
        }, {
          yPercent: -50,
          scale: 1,
          rotate: (i, el, els) => i % 2 === 0 ? -2 : 2,
          stagger: { each: 1 }
        })
      }
    })
  }

  destroy() {
    if (this.mm) this.mm.revert()
  }
}