import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage, domStorage } from '../_globals'
import WknPlyr from '../modules/wkn-plyr'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

export default class PageCandidater extends Renderer {

  initialLoad() {

    const onDOMContentLoaded = () => {
      document.removeEventListener('DOMContentLoaded', onDOMContentLoaded)
      this.onEnter()
    }

    document.addEventListener('DOMContentLoaded', onDOMContentLoaded)

  }

  onEnter() {

    this.DOM = {
      plyrs : viewStorage.current.querySelectorAll('.plyr__container'),
      faq: viewStorage.current.querySelector('.Faq__list'),
    }

    this.init()
  }

  init() {
    if (isDEVMODE) console.log(`Init Page : Page Candidater`)
    if (isDEVMODE) console.log(viewStorage.current)

    const { plyrs, faq } = this.DOM
    plyrs.length > 0 && this.initPlyrs()
    faq && this.initFaq()

    const { joinUs } = domStorage
    joinUs.style.display = "none"
  }

  initFaq() {
    const { faq } = this.DOM
    this.DOM.faqQuestions = faq.querySelectorAll('.Faq__question button.question')
    this.onQuestionToggle = this.onQuestionToggle.bind(this)
    this.DOM.faqQuestions.forEach(question => question.addEventListener('click', this.onQuestionToggle))
  }

  onQuestionToggle(e) {
    const { currentTarget } = e
    e.preventDefault();
    console.log(currentTarget)
    currentTarget.parentNode.classList.toggle('--active')
    ScrollTrigger.refresh()
  }

  initPlyrs() {
    const { plyrs } = this.DOM
    this.plyrs = []
    plyrs.forEach((p) => {
      const myPlyr = new WknPlyr(p)
      console.log(myPlyr)
      this.plyrs.push(myPlyr)
    })
  }

  // onEnterCompleted() {}

  // onLeave() {}

  onLeaveCompleted() {
    this.plyrs && this.plyrs.forEach(plyr => plyr.destroy())
    this.DOM.faqQuestions.forEach(question => question.removeEventListener('click', this.onQuestionToggle))
    
    const { joinUs } = domStorage
    joinUs.style.display = "block"
  }
}
