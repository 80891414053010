import { viewStorage } from '../_globals'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { SplitText } from 'gsap/SplitText'
gsap.registerPlugin(ScrollTrigger, SplitText)

export const pageIntro = (delay = 0.5) => {
  const { current } = viewStorage

  const pageHeader = current.querySelector('.Page__header')
  const pageTitle = pageHeader.querySelector('h1')
  let pageTitleLines = null
  let pageTitleLinesC = null
  const pageHeaderParagraph = pageHeader.querySelector('p')
  let pageHeaderParagraphLines = null
  let pageHeaderParagraphLinesC = null
  const pageHeaderFilters = pageHeader.querySelector('.Filters')

  if (pageTitle) {
    pageTitleLines = new SplitText(pageTitle, { type: 'lines' })
    pageTitleLinesC = new SplitText(pageTitle, { type: 'lines', linesClass: '--oh' })
  }

  if (pageHeaderParagraph) {
    pageHeaderParagraphLines = new SplitText(pageHeaderParagraph, { type: 'lines' })
    pageHeaderParagraphLinesC = new SplitText(pageHeaderParagraph, { type: 'lines', linesClass: '--oh' })
  }

  const tl = gsap
    .timeline({
      delay,
      defaults: { ease: 'power4.out', duration: 1.2 }
    })

    pageTitleLines && tl.fromTo(pageTitleLines.lines, { yPercent: 120 }, { yPercent: 0, stagger: 0.1 }, 0)
    pageHeaderParagraphLines && tl.fromTo(pageHeaderParagraphLines.lines, { yPercent: 100 }, { yPercent: 0, stagger: 0.1 }, 0.3)
    pageHeaderFilters && tl.from(pageHeaderFilters.querySelectorAll('li'), { yPercent: 100, opacity: 0, clearProps: 'all', stagger: 0.02 }, 0.3)
  
  return tl
}
