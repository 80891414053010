import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals'
import WknSwiper from '../modules/wkn-swiper'
import gsap from 'gsap'

export default class PageEcole extends Renderer {

  initialLoad() {

    const onDOMContentLoaded = () => {
      document.removeEventListener('DOMContentLoaded', onDOMContentLoaded)
      this.onEnter()
    }

    document.addEventListener('DOMContentLoaded', onDOMContentLoaded)

  }

  onEnter() {

    this.DOM = {
      teamCategories : viewStorage.current.querySelector('.Team__categories'),
      teamSwipers : viewStorage.current.querySelector('.Team__swipers')
    }

    this.init()
  }

  init() {
    if (isDEVMODE) console.log(`Init Page : Page Ecole`)
    if (isDEVMODE) console.log(viewStorage.current)

    const { teamSwipers } = this.DOM
    teamSwipers && this.initTeam()
  }

  initTeam() {
    console.log('initTeam')
    const { teamCategories, teamSwipers } = this.DOM

    const teamCategoriesSwipers = teamSwipers.querySelectorAll('.Team__swiper')
    this.teamCategoriesLink = teamCategories.querySelectorAll('a')

    // Categories
    this.onCategoryChange = this.onCategoryChange.bind(this)
    this.teamCategoriesLink.forEach(link =>  link.addEventListener('click', this.onCategoryChange))

    // Swipers
    this.swipers = []
    teamCategoriesSwipers.forEach((s) => {
      const mySwiper = new WknSwiper(s, { slidesPerView: 'auto' })
      this.swipers.push(mySwiper)
    });

  }

  onCategoryChange(e) {
    e.preventDefault()
    const { currentTarget } = e
    const { teamCategories, teamSwipers } = this.DOM

    // Remove Prev
    const prevCategory = teamCategories.querySelector('.--active')
    prevCategory && prevCategory.classList.remove('--active')

    const prevSwiper = teamSwipers.querySelector('.--active')
    prevSwiper && prevSwiper.classList.remove('--active')
    
    const currentCategoryIndex = [...teamCategories.querySelectorAll('li')].indexOf(currentTarget.parentNode)
    
    currentTarget.parentNode.classList.add('--active')
    this.swipers[currentCategoryIndex].swiper.el.parentNode.classList.add('--active')
    this.swipers[currentCategoryIndex].swiper.slideTo(0)
    gsap.fromTo(this.swipers[currentCategoryIndex].swiper.el.querySelectorAll('.Teammate__card'), { yPercent: 100 }, { yPercent: 0, clearProps: 'all', stagger: 0.03, ease: 'power4.out', duration: 1.2 })

  }

  // onEnterCompleted() {}

  // onLeave() {}

  onLeaveCompleted() {
    this.swipers.forEach(swiper => swiper.destroy())
    this.teamCategoriesLink.forEach(link => link.removeEventListener('click', this.onCategoryChange))
  }
}
