import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import WknPlyr from '../modules/wkn-plyr'
import TestimoniesSlider from '../modules/testimonies-slider'

export default class PagePedagogie extends Renderer {

  initialLoad() {

    const onDOMContentLoaded = () => {
      document.removeEventListener('DOMContentLoaded', onDOMContentLoaded)
      this.onEnter()
    }

    document.addEventListener('DOMContentLoaded', onDOMContentLoaded)

  }

  onEnter() {

    this.DOM = {
      projects : viewStorage.current.querySelector('.projects'),
      projectsPlyr : viewStorage.current.querySelector('.projects .plyr__container'),
      testimoniesSliderSection: viewStorage.current.querySelector('.TestimoniesSlider')
    }

    this.init()
  }

  init() {
    if (isDEVMODE) console.log(`Init Page : Page Pedagogie`)
    if (isDEVMODE) console.log(viewStorage.current)

    const { projects } = this.DOM
    projects && this.initProjects()

    if (this.DOM.testimoniesSliderSection) {
      this.testimoniesSlider = new TestimoniesSlider(this.DOM.testimoniesSliderSection)
    }
  }

  initProjects() {
    const { projects, projectsPlyr } = this.DOM
    this.projectsPlyr = new WknPlyr(projectsPlyr)
    this.projectsPlyr.plyr.loop = true
    this.projectsPlyr.plyr.muted = true
    this.projectsPlyr.plyr.play()

    this.projectsST = ScrollTrigger.create({
      trigger: projects,
      onToggle: ({isActive}) => {
        if (isActive) {
          this.projectsPlyr.plyr.play()
        } else {
          this.projectsPlyr.plyr.stop()
        }
      }
    })
  }

  // onEnterCompleted() {}

  // onLeave() {}

  onLeaveCompleted() {
    this.projectsPlyr && this.projectsPlyr.destroy()
    this.projectsST && this.projectsST.kill(true)

    if (this.testimoniesSlider) {
      this.testimoniesSlider.destroy()
    }
  }
}
