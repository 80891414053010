import { domStorage } from "../_globals"
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import Logo24 from './logo24'
gsap.registerPlugin(ScrollTrigger)

export default class Footer {
  constructor() {
    this.DOM = { el: domStorage.footer }
    this.DOM.footerLogo = this.DOM.el.querySelector('.l24')
    this.setParallax()
    this.logo24 = new Logo24(this.DOM.footerLogo)
  }

  setParallax() {
    const { el, footerLogo } = this.DOM

    this.tl = gsap
    .timeline({
      defaults: { ease: 'none' },
      scrollTrigger: {
        trigger: el,
        start: 'top bottom',
        end: 'top center',
        scrub: true,
        onEnter : () => this.logo24.play(true)
      }
    })
    .fromTo(footerLogo, { yPercent: -100 }, { yPercent: 0 })
  }
}