import { Renderer } from '@unseenco/taxi';
import { domStorage, isDEVMODE, viewStorage } from '../_globals'
import gsap from 'gsap'

export default class pageResources extends Renderer {

  initialLoad() {

    const onDOMContentLoaded = () => {
      document.removeEventListener('DOMContentLoaded', onDOMContentLoaded)
      this.onEnter()
    }

    document.addEventListener('DOMContentLoaded', onDOMContentLoaded)

  }

  onEnter() {

    this.DOM = {
      ressourcesList: viewStorage.current.querySelector('.Ressources__list'),
      categories: viewStorage.current.querySelectorAll('.select-category'),
      BlockPostResult: document.querySelector('#BlockPostBody'),
      BlockPostPagination: viewStorage.current.querySelector('#BlockPostPagination'),
    };

    this.categorySlug = '';
    this.page = 1;

    this.DOM.categories.forEach((option, index) => {
      option.addEventListener('click', (e) => {
        e.preventDefault();
        const { currentTarget } = e
        viewStorage.current.querySelector('.select-category.--active').classList.remove('--active')
        currentTarget.classList.add('--active')
        this.categorySlug = option.dataset.slug;
        this.runSearch();
      });
    });

    this.init()
  }


  init() {
    if (isDEVMODE) console.log(`Init Page : Page Pedagogie`)
    if (isDEVMODE) console.log(viewStorage.current);
    this.runSearch(false);
  }

  

  initPaginationListeners() {
    const { BlockPostPagination } = this.DOM;
    let buttons = BlockPostPagination.querySelectorAll('a');
    buttons.forEach((button, index) => {
      const m = button.addEventListener('click', (e) => {
        e.preventDefault();
        if (1) {
          this.page = parseInt(e.target.dataset.page);
          this.runSearch();
        }
      });
    });
  }

  onSuccess(responseText, scrollTo = false) {
    const { ressourcesList, BlockPostResult, BlockPostPagination } = this.DOM
    const json = JSON.parse(responseText);

    if (json.BlockPostBody_posts.length == 0) {
      //ReferentNetwork.style.display = 'none'
    } else {
      BlockPostResult.innerHTML = json.BlockPostBody_html;
      BlockPostPagination.innerHTML = json.BlockPostPagination_html;
      this.initPaginationListeners();

      gsap.from(BlockPostResult.querySelectorAll('.news__card'), { opacity: 0, y: 60, duration: 1, ease: 'power4.out', stagger: 0.1, clearProps: 'all'})

      console.log(scrollTo)

      if (scrollTo) {
        const { hasSmoothScroll, viewScroll } = viewStorage
        if (hasSmoothScroll) viewScroll.scroll.scrollTo(ressourcesList, { offset: -120, duration: 0.8, easing: (t) => t < 0.5 ? 4 * t * t * t : 1 - Math.pow(-2 * t + 2, 3) / 2 })
        else gsap.to(window, {duration: 1.8, scrollTo: { y: ressourcesList, offsetY : -120, autoKill: false }, overwrite: 'all', ease: 'expo.inOut' })
      }
    }
  }

  runSearch(scrollTo = true) {
    const { body } = domStorage
    body.classList.add('--loading')
    console.log('runSearch')

    const url = json_data.search_resources_ajax_url;

    const formData = new FormData();
    formData.append('page', this.page);
    formData.append('category_slug', this.categorySlug);
    const xhr = new XMLHttpRequest()
    xhr.open('POST', url, true)
    xhr.onreadystatechange = () => {
      console.log('xhr.onreadystatechange')
      if (xhr.readyState === XMLHttpRequest.DONE) {
        body.classList.remove('--loading')
        switch (xhr.status) {
          case 200:
            this.onSuccess(xhr.responseText, scrollTo)
            break
          case 422:
            break
          default:
            this.onServerError()
        }
      }
    }

    xhr.send(formData)
  }

  // onEnterCompleted() {}

  // onLeave() {}

  onLeaveCompleted() {

  }
}
