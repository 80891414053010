import { isDEVMODE, viewStorage } from '../_globals'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import gsap from 'gsap'

export default class viewSplited {

  constructor(container = viewStorage.current) {

    this.DOM = {
      splitedItems: container.querySelectorAll('*:not(h1).--splited')
    }

    if (this.DOM.splitedItems.length === 0) return

    viewStorage.hasSplited = true

    this.init()

  }

  init() {

    if (isDEVMODE) console.log('Init viewSplited')

    this.isHorizontal = false

    ScrollTrigger.defaults({
      // markers: isDEVMODE
    })

    this.DOM.splitedItems.forEach((el) => {

      const inviewStart = el.dataset.inviewStart ? el.dataset.inviewStart : 'top bottom'
      const inviewEnd = el.dataset.inviewEnd ? el.dataset.inviewEnd : 'bottom top'

      el.scrollTrigger = ScrollTrigger.create({
        trigger: el,
        toggleClass: '--visible',
        start: inviewStart,
        end: inviewEnd,
        onEnter: () => {
          gsap.from(el.querySelectorAll('.--oh'), { xPercent: () => gsap.utils.random(-25, 25), duration: 2.4, ease: 'power4.out', clearProps: 'transform' })
        }
      })
    })
  }

  destroy() {

    if (isDEVMODE) console.log('Destroy viewSplited')

    this.DOM.splitedItems.forEach((el) => {
      if (viewStorage.current.contains(el)) {
        el.scrollTrigger.kill(false)
      }
    })

    viewStorage.hasSplited = false

  }
}
