import Swiper, { Navigation, EffectFade, Autoplay } from 'swiper'
Swiper.use([Navigation, EffectFade, Autoplay])

export default class TestimoniesSlider {
  constructor(el) {
    this.DOM = { el }

    if (!this.DOM.el) return

    this.DOM.swiper = this.DOM.el.querySelector('.swiper')
    this.DOM.slides = this.DOM.el.querySelectorAll('.swiper-slide')
    this.DOM.prev = this.DOM.el.querySelector('.prev')
    this.DOM.next = this.DOM.el.querySelector('.next')
    this.DOM.timeline = this.DOM.el.querySelector('.swiper-timeline')

    if (!this.DOM.swiper) return

    this.setSwiper()
  }

  setSwiper() {
    const { timeline } = this.DOM

    if (this.DOM.slides.length <= 1) return

    this.swiper = new Swiper(this.DOM.swiper, {
      spaceBetween: 100,
      centeredSlides: true,
      autoplay: {
        delay: 15000, // 15sec
        disableOnInteraction: false
      },
      navigation: {
        nextEl: this.DOM.next,
        prevEl: this.DOM.prev
      },
      on: {
        autoplayTimeLeft(s, time, progress) {
          timeline.style.setProperty("--progress", 1 - progress);
        }
      }
    })
  }

  destroy() {
    if (this.swiper) {
      this.swiper.destroy()
    }
  }
}
